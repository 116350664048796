import { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import './Home.css';


function Home({setNav}) {
 
  // when active displays request form and disables other things
  const [popupAct, setPopupAct] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  

  // when user clicks close button on the form
  const handleClose = () => {
    setPopupAct(false)
  }

  const handleInfo = () => {
    setPopupAct(true)
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle menu visibility
  };

  const reviews = [
    {
      name: "Saimouli",
      review: "Honestly, you guys are so patient and humble. I didn't feel any dissatisfaction.",
      rating: 5,
    },
    {
      name: "Laxman Chaudhary",
      review: "I think everything is great, just need to expand the reach to more people.",
      rating: 5,
    },
    {
      name: "Parth Todakar",
      review: "Can introduce more detailed roadmap with good resources to learn something from.",
      rating: 4,
    },

  ];

    // Function to generate stars based on the rating
    const renderStars = (rating) => {
      const fullStars = Math.floor(rating); // Number of full stars
      const emptyStars = 5 - fullStars; // Remaining empty stars
      const starArray = [];
  
      // Add full stars
      for (let i = 0; i < fullStars; i++) {
        starArray.push(<i key={"full-" + i} className="fas fa-star"></i>);
      }
  
      // Add empty stars
      for (let i = 0; i < emptyStars; i++) {
        starArray.push(<i key={"empty-" + i} className="far fa-star"></i>);
      }
  
      return starArray;
    };

  useEffect(() => {

    setNav(false);

  const interval = setInterval(() => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % reviews.length);
  }, 5000); // 200 milliseconds (0.2 seconds)

  return () => clearInterval(interval);
  }, [])
  

  return (
    <div className="Home">
      <div class="fullscreen">
        <div className="jumbotron-container-custom">
        {/* Navbar section */}
          <nav className="navbar-custom">
            <div class="jumbotron-brand">
              <img src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/Untitled_design__9_-removebg-preview.png?alt=media&token=9db08dd0-be43-416c-b2c5-ddc8a29a9e7f" alt="Brand Logo" class="brand-logo" />
              <a class="navbar-brand" href="/">
              <div className="navbar-brand-custom">Sateek</div>
              </a>
            </div>
          
            <div className="hamburger-icon" onClick={toggleMenu}>
              &#9776;
            </div>

            {/* Links, hidden by default on small screens */}
            <ul className={`navbar-links-custom ${isOpen ? 'show' : ''}`}>
              <li><a class="nav-link active" aria-current="page" > <Link style={{ textDecoration: 'none' }} to="/">Home</Link></a></li>
              <li><a class="nav-link active" aria-current="page" > <Link to="/product" style={{ textDecoration: 'none' }}>Products</Link></a></li>
              <li><a class="nav-link active" aria-current="page" > <Link to="/resources" style={{ textDecoration: 'none' }}>Stats for Nerds</Link></a></li>
            </ul>
          </nav>

        {/* Jumbotron content */}
        <div className="jumbotron-content-custom">
          <h1>Book session with verified professionals & get advice over a virtual meet</h1>
          <a href="#works"><button className="jumbotron-button-custom">How it works</button></a>
        </div>

        {/* Feature section below the jumbotron */}
        <div className="features-section-custom">
          <div className="feature-custom">
            <h3 id="jumbo-headers">Access to verified professionals</h3>
            <p class="hide-text">Choose from our list of the experts in a variety of topics.</p>
          </div>
          <div className="feature-custom">
            <h3 id="jumbo-headers">Personalized credible guidance</h3>
            <p class="hide-text">Book a 1-on-1 virtual session & get advice tailored to you.</p>
          </div>
          <div className="feature-custom">
            <h3 id="jumbo-headers">Save time,money & avoid noise</h3>
            <p class="hide-text">We value your resources and strive to deliver crisp to the point advice.</p>
          </div>
        </div>
      </div>
            <div className="carousel-container">
              <div className="carousel">
                <div className="carousel-slide">
                  <h3>{reviews[currentSlide].name}</h3>
                  <p>{reviews[currentSlide].review}</p>
                  <div className="rating">
                  {renderStars(reviews[currentSlide].rating)}
                </div>
              </div>
            </div>
        </div>
      </div>
       
        <div className="consultants" id="counsels">
            <Link style={{ textDecoration: 'none' }} to="/placement">
                <div className="consultant"  id="home_consultant">
                    <img className="catimg center-fit margins"  src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/Untitled_design__14_-removebg-preview.png?alt=media&token=4623fb22-3318-44dd-a283-c6462934af69"/>
                    <p className="field_subtitle">Tech Placement</p>
                    <div class="text-container expand_text">
                    <p className="slide-down-text">If you need advice for preparing and applying for tech jobs<br/></p>
                      <ul class="inline-list">
                        <li className="slide-down-text-1">Resources for prep</li>
                        <li className="slide-down-text-2">DSA</li>
                        <li className="slide-down-text-4">And more</li>
                      </ul> 
                    </div>
                </div>
            </Link>

            <Link style={{ textDecoration: 'none' }} to="/data">
                <div className="consultant"  id="home_consultant">
                    <img className="catimg center-fit margins"  src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/Untitled_design__17___1_-removebg-preview.png?alt=media&token=f898dbe4-e165-48d8-8aa1-5047f3c6d4f0"/>
                    <p className="field_subtitle">AI and Data</p>
                    <div class="text-container expand_text">
                    <p className="slide-down-text"><br/></p>
                      <ul class="inline-list">
                        <li className="slide-down-text-1">How to get into AI and its subfields</li>
                        <li className="slide-down-text-2">Information about fields like Data Analyst, Data Engineering and AI Research </li>
                        <li className="slide-down-text-4">And more</li>
                      </ul> 
                    </div>
                </div>
            </Link>
           
            <Link style={{ textDecoration: 'none' }} to="/mba">
                <div className="consultant"  id="home_consultant">
                    <img className="catimg center-fit margins"  src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/Copy_of_G.A.T.E-removebg-preview.png?alt=media&token=e5980a8a-09e3-4302-a36d-5379d4b61252"/>
                    <p className="field_subtitle">All about MBA</p>
                    <div class="text-container expand_text">
                    <p className="slide-down-text">If you need advice regarding MBA related exams and jobs<br/></p>
                      <ul class="inline-list">
                        <li className="slide-down-text-1">Resources for prep</li>
                        <li className="slide-down-text-2">CV/Inteview Prep</li>
                        <li className="slide-down-text-3">MBA Experience</li>
                        <li className="slide-down-text-4">And more</li>
                      </ul> 
                    </div>
                </div>
            </Link>

            <Link style={{ textDecoration: 'none' }} to="/upsc">
            <div className="consultant" id="home_consultant">
              <img className="catimg center-fit margins" src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/news-31-upsc-removebg-preview.png?alt=media&token=d37ad779-f1ac-4a31-b10b-3a65b37010f3" />
              <p className="field_subtitle">All about UPSC</p>
              <div class="text-container expand_text">
                <p className="slide-down-text">If you need guidance for cracking the UPSC exam and becoming a civil servant<br/></p>
                <ul class="inline-list">
                  <li className="slide-down-text-1">Advice on choosing the right optional subject and finding a suitable coaching center</li>
                  <li className="slide-down-text-2">Strategies for starting out, from understanding the syllabus to creating a study plan</li>
                  <li className="slide-down-text-3">Guidance on backup career options and alternative paths</li>
                </ul> 
              </div>
            </div>
          </Link>


            <Link style={{ textDecoration: 'none' }} to="/abroad">
                <div className="consultant"  id="home_consultant">
                    <img className="catimg center-fit margins"  src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/Untitled_design__20_-removebg-preview.png?alt=media&token=0636e68d-7384-4824-b835-7324cc4adb7a"/>
                    <p className="field_subtitle">Study Abroad</p>
                    <div class="text-container expand_text">
                    <p className="slide-down-text">If you need advice regarding what needs to be done for a smooth moving experience<br/></p>
                      <ul class="inline-list">
                        <li className="slide-down-text-1">Going for further studies</li>
                        <li className="slide-down-text-2">Paperwork and University Selection</li>
                        <li className="slide-down-text-3">Financial Planning</li>
                        <li className="slide-down-text-4">And more</li>
                      </ul> 
                    </div>
                </div>
            </Link>

            <Link style={{ textDecoration: 'none' }} to="/law">
                <div className="consultant"  id="home_consultant">
                    <img className="catimg center-fit margins"  src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/Untitled_design__24_-removebg-preview.png?alt=media&token=48317c11-b62e-4aa5-9e23-890d4c2bba27"/>
                    <p className="field_subtitle">Legal advice and Mentoring</p>
                    <div class="text-container expand_text">
                    <p className="slide-down-text">If you need advice regarding Indian legal matters and Acedemia<br/></p>
                      <ul class="inline-list">
                        <li className="slide-down-text-1">Answers to your legal queries</li>
                        <li className="slide-down-text-2">How to navigate legal situation</li>
                        <li className="slide-down-text-3">How to become a Lawyer</li>
                        <li className="slide-down-text-4">And more</li>
                      </ul> 
                    </div>
                </div>
            </Link>

            <Link style={{ textDecoration: 'none' }} to="/ca">
                <div className="consultant"  id="home_consultant">
                    <img className="catimg center-fit margins"  src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/Untitled_design__15___1_-removebg-preview-removebg-preview.png?alt=media&token=c7bbe767-4018-4a33-8a96-5b22483ab4ae"/>
                    <p className="field_subtitle">
Chartered Accountants</p>
                    <div class="text-container expand_text">
                    <p className="slide-down-text">If you need advice from verified CAs<br/></p>
                      <ul class="inline-list">
                        <li className="slide-down-text-1">How to become a CA</li>
                    
                        <li className="slide-down-text-3">Financial Planning</li>
                        <li className="slide-down-text-4">And more</li>
                      </ul> 
                    </div>
                </div> 
            </Link>

            <Link style={{ textDecoration: 'none' }} to="/graphic">
                <div className="consultant"  id="home_consultant">
                    <img className="catimg center-fit margins"  src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/Untitled_design__13_-removebg-preview.png?alt=media&token=0d282d2a-0d01-433c-859f-7739bf70a3da"/>
                    <p className="field_subtitle">Design and Art</p>
                    <div class="text-container expand_text">
                    <p className="slide-down-text">If you want to get help regarding all things designing<br/></p>
                      <ul class="inline-list">
                        <li className="slide-down-text-1">Product Design</li>
                        <li className="slide-down-text-2">Communication Design</li>
                        <li className="slide-down-text-3">Visual design - graphic design, illustration, etc</li>
                      </ul> 
                    </div>
                </div>
            </Link>

            <Link style={{ textDecoration: 'none' }} to="/trading">
                <div className="consultant"  id="home_consultant">
                    <img className="catimg center-fit margins"  src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/4136942__1_-removebg-preview.png?alt=media&token=ae1f1040-13c7-438e-8344-8e41f017923a"/>
                    <p className="field_subtitle">Trading and Investing</p>
                    <div class="text-container expand_text">
                    <p className="slide-down-text">If you want to get some advice from seasoned traders and investors <br/></p>
                      <ul class="inline-list">
                        <li className="slide-down-text-1">Resources</li>
                        <li className="slide-down-text-2">Avoiding Scams</li>
                        <li className="slide-down-text-3">How to of trading and investing</li>
                      </ul> 
                    </div>
                </div>
            </Link>

            <Link style={{ textDecoration: 'none' }} to="/fitness">
                <div className="consultant"  id="home_consultant">
                    <img className="catimg center-fit margins"  src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/Untitled_design__22_-removebg-preview.png?alt=media&token=01b87e45-87bf-46b4-9cec-3ca83b69e0d8"/>
                    <p className="field_subtitle">Fitness and Nutrition</p>
                    <div class="text-container expand_text">
                    <p className="slide-down-text">If you need advice regarding fitness routines and following the correct diet<br/></p>
                      <ul class="inline-list">
                        <li className="slide-down-text-1">Resources for following the correct diet for your desired body type</li>
                        <li className="slide-down-text-2">Effective Exercise Routine</li>
                        <li className="slide-down-text-2">Advice for managing your nutritional needs</li>
                        <li className="slide-down-text-4">And more</li>
                      </ul> 
                    </div>
                </div>
            </Link>

            <Link style={{ textDecoration: 'none' }} to="/life">
              <div className="consultant" id="home_consultant">
                <img className="catimg center-fit margins" src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/Untitled_design__27_-removebg-preview.png?alt=media&token=ccb436fd-f83d-4f8d-9de7-6c7d693d7bfd" />
                <p className="field_subtitle">Self Improvement and Learning</p>
                <div class="text-container expand_text">
                  <p className="slide-down-text">If you need guidance on personal growth and lifelong learning <br/></p>
                  <ul class="inline-list">
                    <li className="slide-down-text-1">Resources for developing new skills and hobbies</li>
                    <li className="slide-down-text-2">Strategies for effective time management</li>
                    <li className="slide-down-text-3">Advice for building positive habits and routines</li>
                    <li className="slide-down-text-4">And more</li>
                  </ul> 
                </div>
              </div>
          </Link>

           
            <Link style={{ textDecoration: 'none' }} to="/gate">
                <div className="consultant"  id="home_consultant">
                    <img className="catimg center-fit margins"  src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/G.A.T.E-removebg-preview__1_-removebg-preview.png?alt=media&token=fff9694f-c897-4744-bd9d-006eb67941cf"/>
                    <p className="field_subtitle">All about GATE</p>
                    <div class="text-container expand_text">
                    <p className="slide-down-text">If you need advice regarding GATE preparation and experience<br/></p>
                      <ul class="inline-list">
                        <li className="slide-down-text-1">Resources for prep</li>
                        <li className="slide-down-text-2">Future Prospects</li>
                        <li className="slide-down-text-3">M.Tech Experience</li>
                        <li className="slide-down-text-4">And more</li>
                      </ul> 
                    </div>
                </div>
            </Link>


            <Link style={{ textDecoration: 'none' }} to="/group">
                <div className="consultant"  id="home_consultant">
                    <img className="catimg center-fit margins" src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/groupdiscuss-removebg-preview.png?alt=media&token=a9dea51e-ac2b-49b4-8b56-a0a1eef27094"/>
                    <p className="field_subtitle">Group Discussion</p>
                    <div class="text-container expand_text">
                    <p className="slide-down-text">Casual Discussion on Topics<br/></p>
                      <ul class="inline-list">
                        <li className="slide-down-text-1">Language and Interest Matching</li>
                        <li className="slide-down-text-2">Light Hearted Conversations</li>
                      </ul> 
                    </div>
                </div>
            </Link>
        </div>


        
        

         {/* How it works popup */}
      {popupAct && <div className="popup">
        <div className="popup-inner">
        <strong>At Sateek, we focus on providing you with sateek guidance at minimal loss of time and resources.</strong>
        <br/>
        <p>Let’s understand how we do it, and how you can begin your journey with us:</p>

        <ul type="disc">
            <h5 id ="steps_list">Common Steps</h5>
            <li>Click on desired area where you need Sateek guidance.</li>
            <li>Select an appropriate counsel for you using tags and bio as reference.If confused you can contact us.</li>
            <li>Click on the book session button, and fill in the required details.</li>
            <li>Choose the type of session you need from group session or individual one on one session.</li>
            <li>Group session: These are 30 minutes long and will be scheduled based on mentor availability and when at least three participants are available for the time slot.</li>
            <li>One on One session : This is a one on one meet with counsel and it will be charged minimally based on counsel.</li>
            <li>You’ll be getting an email confirmation of the booking from our side after you've submitted the details.</li>
            <br/>
            <h5 id ="steps_list">One on One Session</h5>
            <li>Counsel will set the date and time of the session in case of one on one session (usually within a week).</li>
            <li>All details regarding the session will be shared with you in a follow-up mail from us. Also you'll be getting a call for confirmation.</li>
            <br/>
            <h5 id ="steps_list">Group Session</h5>
            <li>
            Group sessions will happen on the day and time allotted by the counsel (as displayed during booking). The session will proceed based on mentor availability and when at least three participants are available for the time slot. Meeting details will be shared via email.</li>
            <li>This will be a 30 min session, so be mindful of time and ask your specific doubts one at a time maintaining the respectfulness of meet.</li>
            <br/>
            <h5 id ="steps_list">Common tips and points</h5>
            <li>If due to any unforeseen event you have to cancel the session, you can reach out to us in the same mail, an early heads-up would be really helpful in this scenario.</li>
            <li>Get your thoughts in order on what to get out of this session and attend the one-on-one session, be thoughtful of the counsel’s time, and ask for to-the-point guidance.</li>
            <li>We’ll reach out to you post your session for very small feedback via email, which will help us serve you better the next time.</li>
            <li>If there's any thing you find missing or feeling lost you can get in touch with us via mail/call.</li>
        </ul>

        <p>We hope you’ll get the Sateek advice that you were looking for.</p>

            

        <div className="buttons">
          <button type="button" class="remove" onClick={handleClose}>Close</button>
        </div>

          
        </div>
      </div>}

      <div id="works">
          <h3 id="head">Workflow</h3>
          <div class="workflow_image">
            <div className="group1" id="group1" class ="groupA">
            <img className="center-fit marginbottom shrink" src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/Select%20the%20areastream%20where%20you%20need%20guidance.png?alt=media&token=9dac2679-0591-4400-b18d-96d83b45ffa6"/>
            <img className="center-fit marginbottom shrink" src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/Select%20a%20counsel.png?alt=media&token=dab76d45-efa7-440d-92fa-fbdee9f732ee"/>
            </div>
            
            <div className="group2" id="group1" >
            <img className="center-fit marginbottom shrink" src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/Book%20session%20(1).png?alt=media&token=da73904e-2aad-46d2-89cb-8dff6a761547"/>
            <img className="center-fit marginbottom shrink" src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/Check%20your%20mail.png?alt=media&token=10d14a2d-5382-46ff-bd0e-0413420b0375"/>
            </div>

            <div className="group2" id="group1">
            <img className="center-fit marginbottom shrink" src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/P.png?alt=media&token=1cda76e4-a23c-45fd-b648-2f9d31fc42fe"/>
            <img className="center-fit marginbottom shrink" src="https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/Help%20us%20serve%20you%20better.png?alt=media&token=175fb82f-813d-42f9-b07b-4dad9199e1ca"/>
            </div>
          </div>
          <div id="workflow_button"><button class="custom-button" id="learnmore" onClick={handleInfo}>Learn More</button></div>
      </div>  

      <div id="about">
        <h3 id="head">About us</h3>
        <h5><strong><center>At Sateek, We believe that time is the most valuable commodity and there should be no extra noise or unwanted paths in your journey.</center></strong></h5><br/>
        <div class="about_lines">We strive to serve you with the sateek guidance you can get within a short amount of time tailored to your personalized goals/issues.<br />

        <span class="tohide_phone">Our platform connects you with experienced subject matter experts who are passionate about helping you achieve your goals and aspirations and also clearing any roadblocks along the way.</span></div>
        <br /> 
        <h5 id="small_heading">What Sets Us Apart:</h5>
        <div id="about_more">
        <ul>
            <li className="bottom_space"><strong>Interactive Session</strong>&nbsp;<span class="tohide_phone">: One-on-one and group guidance sessions for your personalized learning.</span></li>
            <li className="bottom_space"><strong>Experienced Mentors</strong>&nbsp;<span class="tohide_phone">:Our mentors are highly skilled professionals with a wealth of knowledge and real-world experience in their respective fields.They are committed to guiding you on your journey and sharing invaluable insights to help you succeed.</span></li>
            <li className="bottom_space"><strong>To the Point</strong>&nbsp;<span class="tohide_phone">:We believe in to-the-point guidance, we advise both our user and counsel to keep it to the point and try to get the best out of a single session, with no up-selling or asking for many sessions.</span></li>
            <li className="bottom_space"><strong>Tailored Matchmaking</strong>&nbsp;<span class="tohide_phone">: We understand that each person and their aspirations/problems/roadblocks are unique.On our platform, you can choose from the multiple mentors available who you think can guide you in the best way possible.</span></li>
            <li className="bottom_space"><strong>Flexible Learning</strong>&nbsp;<span class="tohide_phone">:We recognize that life can be busy, and schedules can be unpredictable. Our online sessions allow you to learn on demand and from the comfort of your preferred environment.</span></li>
        </ul>
        </div>

        <p id="about_last">Whether you're a student seeking career advice, a professional looking to upskill, or an individual seeking personal growth, our diverse range of mentors are here to support you in achieving your goals.</p>
      </div>


    </div>
  );
}

export default Home;